import "./assets/App.css";
import "styles/globals.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, {
  useState,
  createContext,
  lazy,
  Suspense,
  useEffect,
} from "react";

import ErrorPage from "./404";

import "./styles/style.css";
import "react-spring-bottom-sheet/dist/style.css";

import ThemeProvider from "styles/ThemeProvider";
import "styles/themes/css-variable/light.css";
import "styles/global";
import GlobalHelmet from "components/global-helmet";

const LandingPage = lazy(() => import("./pages/LandingPage"));
const DetailPage = lazy(() => import("./pages/DetailPage"));
export const AppsContext = createContext({});

function App() {
  const [isSearch, setIsSearch] = useState("");

  return (
    <div className="App font-generalSans">
      <>
        <GlobalHelmet />
        <script
          id="theme-setup"
          async
          dangerouslySetInnerHTML={{
            __html: `
                        const theme = 1;
                        const value = theme == "1" && "light";
                        document.documentElement.dataset.theme = value;
                    `,
          }}
        />
        <Router>
          <AppsContext.Provider value={{ isSearch, setIsSearch }}>
            <ThemeProvider>
              <Suspense fallback={null}>
                <Switch>
                  <Route exact path="/" component={LandingPage}></Route>
                  <Route exact path="/:category">
                    <LandingPage />
                  </Route>
                  <Route path="/details/:_id">
                    <DetailPage />
                  </Route>
                  <Route path="/404">
                    <ErrorPage />
                  </Route>
                </Switch>
              </Suspense>
            </ThemeProvider>
          </AppsContext.Provider>
        </Router>
      </>
    </div>
  );
}

export default App;
